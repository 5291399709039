@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300..700&family=Arvo:wght@400;500;700&family=Orbitron:wght@500&family=Google+Sans:wght@400;500;700&display=swap");

body {
  font-family: "SF Pro", Helvetica, "Roboto", sans-serif;
  background-color: #181818;
  color: white;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
}
